import SettingCategory from "@/models/SettingCategory"

export default function useSettingUtils() {
  function categorize(settings: { name: string; description: string; type: string; value: any }[]) {
    let root = new SettingCategory("root")

    for (let setting of settings) {
      root.addSetting(setting, setting.name)
    }

    return root
  }

  function getTypeIcon(type: string) {
    if (type == "String") return "formatText"
    if (type == "int") return "numeric"
    if (type == "double") return "numeric"
    if (type == "boolean") return "toggleSwitch"
    if (type == "email") return "at"
    if (type.includes("enum")) return "orderBoolDescending"
    return "toggleSwitch"
  }

  function getUnderstandableText(type: string) {
    if (type.toLowerCase() == "string") return "Text"
    if (type.toLowerCase() == "int") return "Number"
    if (type.toLowerCase() == "double") return "Number"
    if (type.toLowerCase() == "boolean") return "True/False"
    if (type.toLowerCase() == "email") return "Email"
    if (type.toLowerCase().includes("language")) return "Language"
    if (type.toLowerCase().includes("measurementunit")) return "Measuring unit"
    return "unknown"
  }

  return {
    categorize,
    getTypeIcon,
    getUnderstandableText,
  }
}

export default class SettingCategory {
  subCategories: { [key: string]: any } = {}
  settings: any[] = []
  collapsed: boolean = false
  filterVisible: boolean = true
  name: string

  constructor(name: string) {
    this.name = name
  }

  addSetting(setting: any, remainingPath: string) {
    let parts = remainingPath.split(".", 3)
    if (parts.length == 1) {
      this.settings.push(setting)
      setting.filterVisible = true
    } else {
      let categoryName = parts[0]

      if (!this.subCategories.hasOwnProperty(categoryName)) {
        this.subCategories[categoryName] = new SettingCategory(categoryName)
      }

      if (parts.length > 2) {
        remainingPath = parts[1] + parts[2] + parts[3]
      }

      this.subCategories[categoryName].addSetting(
        setting,
        remainingPath.replace(categoryName + ".", "")
      )
    }
  }

  filter(query: string): boolean {
    let thisVisible = false

    for (let setting of this.settings) {
      setting.filterVisible = query.trim().length == 0 || setting.name.includes(query)

      if (setting.filterVisible) thisVisible = true
    }

    for (let subCategoryName in this.subCategories) {
      let subCategory = this.subCategories[subCategoryName]
      let subCategoryVisible = subCategory.filter(query)

      if (subCategoryVisible) thisVisible = true
    }

    this.filterVisible = thisVisible
    return thisVisible
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed

    for (let subCategoryName in this.subCategories) {
      this.subCategories[subCategoryName].setCollapsed(collapsed)
    }
  }
}

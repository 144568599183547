
import { ref, watch, defineComponent, reactive } from "vue"
import SettingService from "@/services/SettingService"
import SettingsCollectionRecord from "@/components/settings/general/SettingsCollectionRecord.vue"
import SettingsCollectionForm from "./SettingsCollectionForm.vue"
import useSettingUtils from "@/utils/settings"
import Setting from "@/models/Setting"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/buttons/TertiaryButton.vue"

export default defineComponent({
  components: {
    SettingsCollectionRecord,
    SettingsCollectionForm,
    PrimaryButton,
    TertiaryButton,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props) {
    const permissionAttributes = ref()
    if (!props.organization && !props.computer && !props.user) {
      permissionAttributes.value = {
        permissions: '["SUPER_SALES", "SALES", "FINANCE", "SUPPORT", "DEV"]',
      }
    }
    const { categorize, getTypeIcon, getUnderstandableText } = useSettingUtils()
    const service = new SettingService()
    const selectedOrganization = ref(props.organization)
    const selectedUser = reactive(props.user)
    const selectedComputer = reactive(props.computer)
    const settingList: any = reactive({})
    let transformedArray = reactive([])
    let showAddDialog = ref(false)
    let searchValue = ref("")

    let frameUrl = ref("")
    let frame = document.getElementById("frame")

    function handleIframe(setting: any) {
      let splits = setting.name.split(".")
      let name = splits[1]
      let newSrc = "https://vsol.slab.com/embed/" + name + "-" + setting.slabId
      frameUrl.value = newSrc
      //frame.setAttribute("src", newSrc)
    }

    loadSettings()

    watch(
      () => selectedUser,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedComputer,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedOrganization,
      (newValue, oldValue) => reload()
    )

    watch(
      () => searchValue.value,
      () => {
        if (searchValue.value !== "" && searchValue.value.length > 1) {
          if (settingList.subCategories) {
            for (const subCategory in settingList.subCategories) {
              settingList.subCategories[subCategory].collapsed =
                runThroughCategoryForSearch(subCategory)
            }
          }
        } else {
          toggleImportantSettings(settingList.subCategories)
        }
      }
    )

    function runThroughCategoryForSearch(category: string) {
      let openCategory = ref(false)
      for (let setting of settingList.subCategories[category].settings) {
        if (setting.name.toLowerCase().includes(searchValue.value.toLowerCase())) {
          openCategory.value = true
        }
      }
      return openCategory.value
    }

    function reload() {
      showAddDialog.value = false
      transformedArray = []
      Object.assign(settingList, null)
      loadSettings()
    }

    function loadSettings() {
      service.get().then((result: any) => {
        let sortedData = []
        let sortedResult = result.data.map((setting: any) => {
          return new Setting(
            setting.name ? setting.name : null,
            setting.description ? setting.description : null,
            setting.type ? setting.type : null,
            setting.defaultValue || setting.type === "boolean" || setting.type === "int"
              ? setting.defaultValue
              : null,
            setting.slabId ? setting.slabId : null
          )
        })
        sortedData = sortData(sortedResult)
        transformArray(sortedData)
        Object.assign(settingList, categorize(transformedArray))
        openImportantSettings(settingList.subCategories)
      })
    }

    function sortData(data: any) {
      let result = data.sort((a: any, b: any) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      let index = 0
      let previousCategory: string = null
      let finalResult: string[] = []
      result.forEach((item: any) => {
        let settingCategory = item.name.split(".")[0]
        if (settingCategory !== previousCategory && previousCategory) {
          index++
        }
        item.index = index
        finalResult.push(item)
        previousCategory = settingCategory
      })
      return finalResult
    }

    function transformArray(items: any) {
      for (let i = 0; i < items.length; i++) {
        transformedArray.push(items[i])
      }
    }

    function openImportantSettings(subCategories: any) {
      subCategories["dicom"].collapsed = true
      subCategories["ui"].collapsed = true
      subCategories["mail"].collapsed = true
      subCategories["report"].collapsed = true
    }

    function collapseAction(category: any) {
      category.collapsed = !category.collapsed
    }

    let isOpened = ref(false)

    function toggleImportantSettings(subCategories: any) {
      if (isOpened.value === true) {
        openImportantSettings(subCategories)
      } else {
        closeAllSettings(subCategories)
      }
      isOpened.value = !isOpened.value
    }

    function toggleAllSettings(subCategories: any) {
      for (const subCategory in subCategories) {
        subCategories[subCategory].collapsed = isOpened.value
      }
      isOpened.value = !isOpened.value
    }

    function toggleFilledSettings(subCategories: any) {
      if (isOpened.value === true) {
        for (const subCategory in subCategories) {
          subCategories[subCategory].collapsed = checkIfSubCategoryHasAnyFilled(
            subCategories[subCategory]
          )
          openFilledCategorySetting(subCategories[subCategory])
        }
      } else {
        closeAllSettings(subCategories)
      }
      isOpened.value = !isOpened.value
    }

    function checkIfSubCategoryHasAnyFilled(subCategory: any) {
      let opened = false
      subCategory.settings.forEach((setting: any) => {
        if (setting.filled > 0) opened = true
      })
      return opened
    }

    function openFilledCategorySetting(subCategory: any) {
      for (let setting of subCategory.settings) {
        if (setting.filled > 0) {
          setting.collapsed = true
        } else {
          setting.collapsed = false
        }
      }
    }

    function closeAllSettings(subCategories: any) {
      for (const subCategory in subCategories) {
        subCategories[subCategory].collapsed = false
      }
    }

    return {
      selectedComputer,
      selectedOrganization,
      selectedUser,
      getTypeIcon,
      getUnderstandableText,
      transformedArray,
      settingList,
      collapseAction,
      toggleImportantSettings,
      reload,
      loadSettings,
      showAddDialog,
      searchValue,
      handleIframe,
      frameUrl,
      toggleAllSettings,
      toggleFilledSettings,
      permissionAttributes,
    }
  },
})

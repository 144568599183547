export default class {
  name: string
  description: string
  type: string
  value: any
  collapsed: boolean
  filterVisible: boolean
  slabId: string

  constructor(name: string, description: string, type: string, value: any, slabId: string) {
    this.name = name
    this.description = description
    this.type = type
    this.value = value
    this.slabId = slabId
    this.collapsed = true
    this.filterVisible = true
  }
}
